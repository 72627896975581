export enum UserRoleEnum {
    SALES_REPRESENTATIVE = "Sales Rep",
    SUPPORT_TEAM = "Support Team",
    ADMIN = "Admin",
    BRANCH_MANAGER = "Branch Manager",
    PHOTOGRAPHER = "Photographer",
    MOUNTER = "Mounter",
    PRINTER = "Printer",
    WAREHOUSE = "Warehouse"

}

