import { Injectable, Injector } from '@angular/core';
import { AppUrls } from '../../../../../services/urls';
import { StateService } from '../../../../../services/shared/states.service';
import { DistrictService } from '../../../../../services/shared/district.service';
import { CityService } from '../../../../../services/shared/cities.service';
import * as utils from '../../../../../helpers/utils';
import { UserService } from '../../../../../services/shared/user.service';
import { HttpClient } from "@angular/common/http";
import { BASE_URL } from '../../../../../services/base-service/api.service';


@Injectable()
export class SrUIService {

    baseUrl: string;

    constructor(
        private stateService: StateService,
        private districtService: DistrictService,
        private cityService: CityService,
        private userService: UserService,
        private http: HttpClient,
        private injector: Injector,
    ) {
        this.baseUrl = this.injector.get(BASE_URL);
    }


    getStatesByCountryCode(params) {
        return this.stateService.get(params);
    }

    getDistrictsByStates(params) {
        return this.districtService.get(params);
    }

    getCitiesByDistricts(params) {
        return this.cityService.get(params);
    }

    getSrInterestedCities() {
        const email = utils.getLoggedInUserEmail();
        return this.userService.get({ emailId: email }, AppUrls.FETCH_SR_TERRITORY);
    }

    removeSrInterestedCity(id) {
        const email = utils.getLoggedInUserEmail();
        if (navigator.onLine) {
            return this.http.delete(this.baseUrl + 'users' + AppUrls.REMOVE_SR_TERRITORY, { params: { emailId: email, cityId: id } });

        }
    }

    removeAllInterestedCities() {
        const email = utils.getLoggedInUserEmail();
        return this.userService.get({ emailId: email }, AppUrls.REMOVE_ALL_SR_TERRITORY);
    }

}
